.ems-item-view-share-content-play-original .ems-item-view-content {
  flex-direction: column;
}

.ems-item-view-share-content-play-original .ems-item-view-content-image {
  width: 100%;
}

.ems-item-view-share-content-play-original .ems-item-view-content-details {
  width: 100%;
  margin-top: 30px;
}

.ems-item-view-share-content-play-original .ems-item-view-content-details-buttons {
  margin-bottom: 5px;
}

